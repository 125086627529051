import $ from 'jquery';


import './../../scss/blocks/block-news2.scss';

$(document).ready(function() {

  $('.news2-header .category-name').on('click',function(e){
    let thisdata = $(this).attr('data-term');
    $('.news2-header .category-name').removeClass('active')
    $(this).addClass('active')
    $('.news2-content-section .category-content').removeClass('active')
    $('.news2-content-section .category-content[data-term="'+thisdata+'"]').addClass('active')
  })
})